import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import "./LiftList.css";
import { ColumnDef, createColumnHelper } from "@tanstack/react-table";
import { DeviceListItem } from "../../models/device-list-item";
import { fetchAllTenantDevices } from "../../services/devices-api";
import { CliftReducerAction } from "../../context/clift-context/clift-action-types";
import { getErrorCode } from "../../services/clift-api-errors";
import { useCliftContext } from "../../hooks/useCliftContext";
import { DeviceSortableColumns } from "../../models/device";
import PaginatedList from "../../components/PaginatedList";
import { Direction, SearchParams, Sort } from "../../models/search";

export const LiftSimpleList = () => {
  const { t, i18n } = useTranslation();
  const columnHelper = createColumnHelper<DeviceListItem>();
  const { cliftState, dispatchCliftState } = useCliftContext();
  const [devices, setDevices] = useState<DeviceListItem[]>([]);
  const [totalLifts, setTotalLifts] = useState<number>(0);
  const [sort, setSort] = useState<Sort>({
    direction: Direction.ASC,
    column: DeviceSortableColumns.MODEL,
  });
  const handleSort = (column: DeviceSortableColumns) => {
    setSort((prevSort) => ({
      column,
      direction:
        prevSort.column === column && prevSort.direction === Direction.ASC
          ? Direction.DESC
          : Direction.ASC,
    }));
  };
  const columns: ColumnDef<DeviceListItem, string>[] = useMemo(
    () => [
      columnHelper.accessor("serialNumber", {
        header: () => (
          <div onClick={() => handleSort(DeviceSortableColumns.SERIAL_NUMBER)}>
            {t("serial_number", { ns: "lift" })}
            {sort.column === DeviceSortableColumns.SERIAL_NUMBER &&
              (sort.direction === Direction.ASC ? " ▲" : " ▼")}
          </div>
        ),
        cell: (info) => info.row.original.serialNumber,
      }),
      columnHelper.accessor("model", {
        header: () => (
          <div onClick={() => handleSort(DeviceSortableColumns.MODEL)}>
            {t("model", { ns: "lift" })}
            {sort.column === DeviceSortableColumns.MODEL &&
              (sort.direction === Direction.ASC ? " ▲" : " ▼")}
          </div>
        ),
        cell: (info) => info.getValue(),
      }),

      columnHelper.accessor("connected", {
        header: () => (
          <div onClick={() => handleSort(DeviceSortableColumns.CONNECTED)}>
            {t("connected", { ns: "lift" })}
            {sort.column === DeviceSortableColumns.CONNECTED &&
              (sort.direction === Direction.ASC ? " ▲" : " ▼")}
          </div>
        ),
        cell: (info) =>
          info.getValue()
            ? t("connected", { ns: "lift" })
            : t("disconnected", { ns: "lift" }),
      }),
    ],
    [i18n.language, sort]
  );

  const fetchLifts = (searchParams: SearchParams) => {
    return fetchAllTenantDevices(searchParams, cliftState.currentTenant?.id)
      .then((res) => {
        setTotalLifts(res.total);
        setDevices(res.content);
      })
      .catch((err) => {
        setDevices([]);
        dispatchCliftState({
          type: CliftReducerAction.AddAlert,
          alert: t("lift_list_http_fail", {
            ns: "alerts",
            code: getErrorCode(err),
          }),
        });
      });
  };

  return (
    <PaginatedList<DeviceListItem>
      sort={sort}
      columns={columns}
      data={devices}
      showSearch={true}
      fetchData={fetchLifts}
      totalElements={totalLifts}
    />
  );
};
