import { useState } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toast";
import { ReactComponent as PlusIcon } from "../../assets/Plus.svg";
import Page from "../../components/Page";
import { CliftReducerAction } from "../../context/clift-context/clift-action-types";
import { useCliftContext } from "../../hooks/useCliftContext";

// TODO: Add this on later Jira ticket
//import { ReactComponent as PlusIcon } from "../../assets/Plus.svg";
import { ConfirmDelete } from "../../components/ConfirmDelete";
import {
  getErrorCode,
  getLocalizedErrorReason,
} from "../../services/clift-api-errors";
import {
  CustomerDevice,
  removeDeviceFromCustomer,
} from "../../services/customers-api";
import { factoryResetDevice } from "../../services/devices-api";
import {
  removeDeviceFromTenant,
  TenantDevice,
} from "../../services/tenants-api";
import { LiftList } from "./LiftList";
import { LiftSimpleList } from "./LiftSimpleList";

export const LiftsPage = () => {
  const { t } = useTranslation();
  const { cliftState, dispatchCliftState } = useCliftContext();
  const [selectedDevice, setSelectedDevice] = useState<CustomerDevice>();
  const [selectedTenantDevice, setSelectedTenantDevice] =
    useState<TenantDevice>();
  const [selectedResetDevice, setSelectedResetDevice] =
    useState<TenantDevice>();

  if (!cliftState.currentTenant) {
    return <></>;
  }

  const checkRemoveErrors = (err: Error) => {
    dispatchCliftState({
      type: CliftReducerAction.AddAlert,
      alert: t("delete_device_http_fail", {
        ns: "alerts",
        code: getErrorCode(err),
        reason: getLocalizedErrorReason(err),
      }),
    });
    toast.error(
      t("error") +
        t("delete_device_toast_error", {
          ns: "alerts",
          reason: getLocalizedErrorReason(err),
        })
    );
  };

  const handleRemoveDeviceFromCustomer = async () => {
    if (selectedDevice === undefined) return;
    if (!cliftState.currentTenant) return;

    await removeDeviceFromCustomer(
      selectedDevice,
      cliftState.currentTenant.id
    ).catch((err) => {
      checkRemoveErrors(err);
    });

    setSelectedDevice(undefined);
  };

  const handleRemoveDeviceFromTenant = async () => {
    if (selectedTenantDevice === undefined) return;

    await removeDeviceFromTenant(selectedTenantDevice).catch((err) => {
      checkRemoveErrors(err);
    });

    setSelectedTenantDevice(undefined);
  };

  const handleResetDevice = async () => {
    if (selectedResetDevice === undefined) return;

    await factoryResetDevice(selectedResetDevice).catch((err) => {
      checkRemoveErrors(err);
    });

    setSelectedDevice(undefined);
    setSelectedTenantDevice(undefined);
    setSelectedResetDevice(undefined);
  };

  const handleAddNewLift = () => {
    if (!cliftState.currentTenant) return;

    dispatchCliftState({
      type: CliftReducerAction.AddTenantLifts,
      tenantID: cliftState.currentTenant.id,
    });
  };

  return (
    <Page
      title={t("lifts", { ns: "lift" })}
      subtitle={t("lifts_subtitle", { ns: "lift" })}
      button={
        cliftState.currentTenant.tenantType === "DEALER" && (
          <button className="button" type="button" onClick={handleAddNewLift}>
            <PlusIcon />
            {t("add_new_lift", { ns: "lift" })}
          </button>
        )
      }
    >
      <>
        {cliftState.currentTenant.tenantType === "ROOT" ||
        cliftState.currentTenant.tenantType === "COUNTRY" ? (
          <LiftSimpleList />
        ) : (
          <LiftList
            tenant={cliftState.currentTenant}
            selectedDevice={selectedDevice}
            setSelectedDevice={setSelectedDevice}
            setSelectedTenantDevice={setSelectedTenantDevice}
            setSelectedResetDevice={setSelectedResetDevice}
          />
        )}

        {selectedDevice !== undefined && (
          <ConfirmDelete
            title={t("remove_lift_question", { ns: "dialogs" })}
            text={t("confirm_lift_remove_question", {
              ns: "dialogs",
              device: selectedDevice.deviceID,
            })}
            isOpen={selectedDevice !== undefined}
            onCancel={() => setSelectedDevice(undefined)}
            onConfirmed={() => {
              handleRemoveDeviceFromCustomer();
            }}
          />
        )}

        {selectedTenantDevice !== undefined && (
          <ConfirmDelete
            title={t("remove_lift_question", { ns: "dialogs" })}
            text={t("confirm_lift_remove_from_tenant_question", {
              ns: "dialogs",
              device: selectedTenantDevice.deviceID,
            })}
            isOpen={selectedTenantDevice !== undefined}
            onCancel={() => setSelectedTenantDevice(undefined)}
            onConfirmed={() => {
              handleRemoveDeviceFromTenant();
            }}
          />
        )}
        {selectedResetDevice !== undefined && (
          <ConfirmDelete
            title={t("factory_reset_lift_question", { ns: "dialogs" })}
            text={t("confirm_factory_reset_lift_question", {
              ns: "dialogs",
              device: selectedResetDevice.deviceID,
            })}
            isOpen={selectedResetDevice !== undefined}
            onCancel={() => setSelectedResetDevice(undefined)}
            onConfirmed={() => {
              handleResetDevice();
            }}
          />
        )}
      </>
    </Page>
  );
};
